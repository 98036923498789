var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"opacity":"0.17","blur":"1rem","show":_vm.showOverlay,"rounded":"md","variant":"secondary"}},[_c('validation-observer',{ref:"feature_edit_form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleUpdateClick.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Name"}},[_c('validation-provider',{attrs:{"vid":"title","name":"Name","rules":{
            required: true,
            min: 3,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Description"}},[_c('validation-provider',{attrs:{"vid":"description","name":"Description","rules":{
            required: true,
            min: 3,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"rows":"3","max-rows":"4"},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"w-100 d-flex justify-content-center align-items-center"},[_c('b-button',{attrs:{"size":"sm","type":"submit","variant":"success","disabled":invalid}},[(_vm.isLoading)?_c('b-spinner',{staticClass:"mr-50",attrs:{"label":"Loading...","small":""}}):_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Update")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }