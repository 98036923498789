export default {
  methods: {
    getFeatures(params) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_SSO_API_URL}/features`,
          params: params,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getFeature(id) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_SSO_API_URL}/features/${id}`,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    updateFeature(featureId, data) {
      console.log('Update Feature', data)
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('_method', 'PUT');
        Object.keys(data).forEach((key) => {
          if (data[key] && data[key] != null) {
            formData.append(key, data[key]);
          }
        });

        const options = {
          method: "POST",
          url: `${process.env.VUE_APP_SSO_API_URL}/features/${featureId}`,
          data: formData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
