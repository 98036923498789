<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
  >
    <validation-observer ref="feature_edit_form" #default="{ invalid }">
      <b-form @submit.prevent="handleUpdateClick">
        <b-form-group label="Name">
          <validation-provider
            vid="title"
            #default="{ errors }"
            name="Name"
            :rules="{
              required: true,
              min: 3,
            }"
          >
            <b-form-input v-model="formData.title" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Description">
          <validation-provider
            vid="description"
            #default="{ errors }"
            name="Description"
            :rules="{
              required: true,
              min: 3,
            }"
          >
            <b-form-textarea
              v-model="formData.description"
              rows="3"
              max-rows="4"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <div class="w-100 d-flex justify-content-center align-items-center">
          <b-button
            size="sm"
            type="submit"
            variant="success"
            :disabled="invalid"
          >
            <b-spinner
              v-if="isLoading"
              label="Loading..."
              small
              class="mr-50"
            />
            <feather-icon v-else icon="CheckIcon" class="mr-50" />
            <span class="align-middle">Update</span></b-button
          >
        </div>
      </b-form>
    </validation-observer>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BBadge,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BButton,
  BForm,
  BFormTextarea,
  BSpinner,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ResponseMixins from "../../../mixins/ResponseMixins";
import FeatureMixins from "../../../mixins/FeatureMixins";
export default {
  mixins: [ResponseMixins, FeatureMixins],
  components: {
    BOverlay,
    BBadge,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BForm,
    BFormTextarea,
    BSpinner,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    required,
  },

  data() {
    return {
      showOverlay: false,
      isLoading: false,
      feature: {},

      formData: {
        title: null,
        description: null,
      },
    };
  },

  mounted() {
    this.load();
  },

  props: {
    featureId: {
      type: String,
      required: true,
    },
    reloadParent: {
      type: Function,
      required: true,
    },
    closeAction: {
      type: Function,
      required: true,
    },
  },

  methods: {
    load() {
      this.getFeatureDetailsAndSetData();
    },

    getFeatureDetailsAndSetData() {
      this.showOverlay = true;
      this.getFeature(this.featureId)
        .then((res) => {
          this.$set(this.formData, "title", res.data.data.title);
          if (res.data.data.description && res.data.data.description != null) {
            this.$set(this.formData, "description", res.data.data.description);
          }
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleUpdateClick() {
      this.isLoading = true;
      this.updateFeature(this.featureId, this.formData)
        .then((res) => {
          this.handleResponse(res);
          this.closeAction();
          this.reloadParent();
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style>
</style>