<template>
  <sidebar-template title="Feature Details" :closeAction="closeAction">
    <template #content>
      <div
        v-if="feature && feature != null"
        class="w-100 h-auto d-flex flex-column align-items-center justify-content-start"
      >
        <div class="w-100 d-flex align-items-start justify-content-start">
          <div
            class="d-flex flex-column align-items-start justify-content-start"
          >
            <h3 class="mb-50">
              <span class="align-middle">{{ feature.title }}</span>
            </h3>
            <p>{{ feature.description }}</p>

            <table class="table table-bordered table-sm">
              <tr>
                <td>Module:</td>
                <td>
                  <b-badge variant="primary" class="ml-50">{{
                    feature.module
                  }}</b-badge>
                </td>
              </tr>

              <tr>
                <td>Unit:</td>
                <td>
                  <b-badge variant="success" class="ml-50">{{
                    feature.unit
                  }}</b-badge>
                </td>
              </tr>

              <tr>
                <td>Credits Per Unit:</td>
                <td>
                  <span class="ml-50 font-weight-bold">{{
                    feature.tariff
                  }}</span>
                </td>
              </tr>
            </table>

            <div
              v-if="
                standards &&
                standards != null &&
                standards.length > 0
              "
              class="w-100 d-flex flex-column align-items-start justify-content-start mt-3"
            >
              <p class="font-weight-bold"><span class="mr-25">Standards</span><b-badge pill variant="warning">{{standards.length}}</b-badge></p>
              <div
                class="w-100 d-flex flex-column align-items-start justify-content-start"
              >
                <ul>
                  <template v-for="std in standards">
                    <li :key="std._id">
                    {{std.name}}
                    </li>
                  </template>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </sidebar-template>
</template>

<script>
import SidebarTemplate from "../../components/SidebarTemplate.vue";
import { BBadge, BButton } from "bootstrap-vue";
import ResponseMixins from "../../../mixins/ResponseMixins";
import FeatureMixins from "../../../mixins/FeatureMixins";

export default {
  mixins: [ResponseMixins, FeatureMixins],

  components: {
    SidebarTemplate,
    BBadge,
    BButton,
  },

  data() {
    return {
      feature: null,
      standards:null,
    };
  },

  mounted() {
    this.load();
  },

  props: {
    closeAction: {
      type: Function,
      required: true,
    },
    featureId: {
      type: String,
      required: true,
    },

    restrictions: {
      type: Array,
      required: false,
      default: null,
    },
  },

  methods: {
    load() {
      if (this.featureId && this.featureId != null) {
        this.getFeatureDetailsAndSetData(this.featureId);
      }
    },

    getFeatureDetailsAndSetData(id) {
      this.getFeature(id)
        .then((res) => {
          console.log(res);
          this.feature = res.data.data;
          if (
            res.data.data.meta &&
            res.data.data.meta!=null &&
            !Array.isArray(res.data.data.meta) &&
            res.data.data.meta.data_url &&
            res.data.data.meta.data_url != null
          ) {
            this.getStandardsFromURL(res.data.data.meta.data_url)
              .then((stds) => {
                this.standards = stds.data.data.filter(std=>this.restrictions.includes(std._id))
                console.log(this.standards)
              })
              .catch((err) => {
                this.handleError(err);
                console.log(err);
              })
              .finally(() => {
                this.showOverlay = false;
              });
          }
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        });
    },

    getStandardsFromURL(url) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: url,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
</script>

<style lang="scss">
.custom-collapse {
  .card {
    .card-header {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0.5rem;
    }

    .card-body {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
</style>