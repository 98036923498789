<template>
  <div class="w-100 h-auto">
    <b-modal ref="edit-feature-modal" hide-footer title="Edit Feature">
      <feature-edit-form
        :closeAction="hideEditFeatureModal"
        :featureId="selectedFeatureId"
        :reloadParent="load"
      />
    </b-modal>
    <b-sidebar
      id="sidebar-feature-details"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
      lazy
      width="420px"
    >
      <feature-details-sidebar
        :closeAction="closefeatureDetailsSidebar"
        :featureId="selectedFeatureId"
      />
    </b-sidebar>
    <div class="card">
      <div class="card-header pb-75" style="padding-right: 0">
        <div class="w-100 row justify-content-between align-items-center">
          <div
            class="d-flex flex-column align-items-start justify-content-start col-md-6 col-sm-12"
          >
            <h3 class="mb-25 font-weight-bolder">Manage Features</h3>
            <p class="mb-0">Manage features across all the systems.</p>
          </div>
          <div class="col-md-6 col-sm-12">
            <div
              class="w-100 d-flex align-items-center justify-content-md-end justify-content-sm-end"
            ></div>
          </div>
        </div>
      </div>
      <div
        class="w-100 d-flex flex-row align-items-end justify-content-between px-1 pb-75"
      >
        <div
          class="app-fixed-search rounded border shadow-sm mb-0"
          style="width: 50%"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                v-if="filters.search == null || filters.search == ''"
                icon="SearchIcon"
                class="text-muted"
              />
              <feather-icon
                @click="
                  () => {
                    filters.search = null;
                  }
                "
                v-else
                icon="XIcon"
                class="text-danger cursor-pointer"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="filters.search"
              placeholder="Search..."
              debounce="500"
              size="md"
            />
          </b-input-group>
        </div>

        <div style="width: 20%">
          <b-form-group class="mb-0">
            <label><small>Filter by Module</small></label>
            <b-form-select v-model="filters.module" :options="moduleOptions" />
          </b-form-group>
        </div>
      </div>
      <div class="position-relative table-responsive" style="min-height: 50vh">
        <b-overlay
          variant="white"
          :show="showOverlay"
          spinner-variant="primary"
          blur="0"
          opacity=".75"
          rounded="sm"
        >
          <div class="table-responsive" style="min-height: 50vh">
            <table role="table" class="table table-hover">
              <thead role="rowgroup">
                <tr role="row">
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Source</th>
                  <th scope="col" class="text-center">Quantity</th>
                  <th scope="col" class="text-center">Actions</th>
                </tr>
              </thead>
              <tbody role="rowgroup">
                <template v-if="Object.keys(features).length > 0">
                  <!-- <tr
                    role="row"
                    v-for="(feature, key, index) in features"
                    :key="key"
                    @click="handleDetailViewClick(feature._id)"
                  > -->
                  <tr
                    role="row"
                    v-for="(feature, key, index) in features"
                    :key="key"
                  >
                    <td scope="row">
                      {{ index + pagination.from }}
                    </td>

                    <td role="cell">
                      <div
                        class="d-flex flex-column align-items-start justify-content-start"
                      >
                        <p
                          class="mb-25 font-weight-bold text-truncate"
                          style="max-width: 240px"
                          v-b-tooltip.hover.bottom.v-primary
                          :title="feature.title"
                        >
                          {{ `${feature.title}` }}
                        </p>
                        <small>{{ feature.description }}</small>
                      </div>
                    </td>

                    <td role="cell">
                      <div
                        class="d-flex flex-column align-items-start justify-content-start"
                      >
                        <b-badge
                          v-if="feature.module && feature.module != null"
                          variant="primary"
                          >{{ feature.module }}</b-badge
                        >
                      </div>
                    </td>

                    <td role="cell">
                      <div
                        class="d-flex align-items-center justify-content-center w-100"
                        v-if="feature.unit && feature.unit != null"
                      >
                        <div
                          v-if="feature.unit && feature.unit == 'count'"
                          class="d-flex flex-column align-items-center justify-content-center"
                        >
                          <label class="font-weight-bolder text-primary"
                            >Count</label
                          >
                          <b-badge variant="dark">{{
                            feature.quantity && feature.quantity != null
                              ? feature.quantity
                              : 0
                          }}</b-badge>
                        </div>

                        <div
                          v-else-if="feature.unit && feature.unit == 'credit'"
                          class="d-flex flex-column align-items-center justify-content-center"
                        >
                          <label class="font-weight-bolder text-primary"
                            >Credits Per Unit</label
                          >
                          <b-badge variant="dark">{{
                            feature.tariff && feature.tariff != null
                              ? feature.tariff
                              : 0
                          }}</b-badge>
                        </div>

                        <div
                          v-else-if="feature.unit && feature.unit == 'boolean'"
                          class="d-flex flex-column align-items-start justify-content-center"
                        >
                          <label class="font-weight-bolder text-success"
                            >Enabled</label
                          >
                          <b-form-checkbox
                            checked="true"
                            class="custom-control-success"
                            name="check-button"
                            switch
                            :disabled="true"
                          >
                            <span class="switch-icon-left">
                              <feather-icon icon="CheckIcon" />
                            </span>
                            <!-- <span class="switch-icon-right">
                                <feather-icon icon="BellOffIcon" />
                              </span> -->
                          </b-form-checkbox>
                        </div>
                      </div>
                    </td>

                    <td role="cell">
                      <div
                        class="d-flex justify-content-center align-items-center"
                      >
                        <b-button
                          @click.stop="handleEditFeatureClick(key)"
                          variant="flat-info"
                          class="btn-icon"
                          ><feather-icon icon="EditIcon"
                        /></b-button>
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <empty-table-section title="Features Empty">
                    <template #content>
                      <p class="font-weight-bold text-center">
                        No features have been found.
                      </p>
                    </template>
                  </empty-table-section>
                </template>
              </tbody>
            </table>
          </div>
        </b-overlay>
      </div>
      <div class="card-body my-0 py-0">
        <div class="d-flex align-items-center justify-content-center">
          <b-pagination
            v-model="filters.page"
            :total-rows="pagination.total"
            :per-page="pagination.perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";

import {
  BPagination,
  BPaginationNav,
  BButton,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BForm,
  BFormInput,
  BBadge,
  BFormSelect,
  BInputGroupPrepend,
  BInputGroup,
  VBTooltip,
  BSidebar,
  BOverlay,
  BFormGroup,
  BFormCheckbox,
} from "bootstrap-vue";
import ResponseMixins from "@/mixins/ResponseMixins";
import FeatureMixins from "@/mixins/FeatureMixins";

import EmptyTableSection from "../components/EmptyTableSection.vue";
import FeatureDetailsSidebar from "./components/FeatureDetailsSidebar.vue";
import FeatureEditForm from "./components/FeatureEditForm.vue";

export default {
  mixins: [ResponseMixins, FeatureMixins],
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BForm,
    BFormInput,
    BBadge,
    BFormSelect,
    BInputGroupPrepend,
    BInputGroup,
    BSidebar,
    BOverlay,
    EmptyTableSection,
    FeatureDetailsSidebar,
    FeatureEditForm,
    BFormGroup,
    BFormCheckbox,
  },

  computed: {
    moduleOptions() {
      return [
        { text: "--Select--", value: null },
        { text: "Admin Portal", value: "Admin Portal" },
        { text: "GRC", value: "GRC" },
        { text: "Secusy App", value: "Secusy App" },
        { text: "Threat Management", value: "Threat Management" },
        {
          text: "Third Party Risk Management",
          value: "Third Party Risk Management",
        },
      ];
    },
  },
  data() {
    return {
      selectedFeatureId: null,
      pagination_pos: "center",

      showOverlay: false,
      pagination: {
        perPage: 10,
        total: 1,
        from: 1,
      },

      filters: {
        search: null,
        page: 1,
        module: null,
      },

      features: {},
    };
  },
  watch: {
    filters: {
      handler: function (newValues) {
        this.getFeaturesAndSetData(newValues);
      },
      deep: true,
    },
  },
  mounted: function () {
    this.load();
  },
  methods: {
    load: function () {
      this.getFeaturesAndSetData(this.filters);
    },

    handleEditFeatureClick(id) {
      this.selectedFeatureId = id;
      this.showEditFeatureModal();
    },

    showEditFeatureModal() {
      this.$refs["edit-feature-modal"].show();
    },
    hideEditFeatureModal() {
      this.$refs["edit-feature-modal"].hide();
    },

    handleDetailViewClick(id) {
      this.selectedFeatureId = id;
      this.openfeatureDetailsSidebar();
    },

    openfeatureDetailsSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-feature-details");
    },

    closefeatureDetailsSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-feature-details");
    },

    getFeaturesAndSetData(params) {
      this.getFeatures(params)
        .then((res) => {
          console.log(res);
          const newFeatures = {};
          res.data.data.data.forEach((feature) => {
            newFeatures[feature._id] = feature;
          });
          this.features = newFeatures;
          this.$set(this.filters, "page", res.data.data.current_page);
          this.$set(this.pagination, "perPage", res.data.data.per_page);
          this.$set(this.pagination, "total", res.data.data.total);
          this.$set(this.pagination, "from", res.data.data.from);
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
th {
  background: #472183 !important;
  color: #fff;
  text-transform: capitalize !important;
}
</style>
  
<style lang="css" scoped>
.table th {
  text-transform: none;
}
</style>
  
<style lang="scss">
.app-fixed-search {
  background-color: transparent;

  .input-group:focus-within {
    box-shadow: none;
  }

  input,
  .input-group-text {
    border: 0;
    background-color: transparent;
  }
}
</style>